import { components, paths } from "@minuthq/minut-api-types/schema"

import {
  IBillingAddress,
  ICustomer,
  IEstimate,
  IPaymentSource,
  ISubscription,
} from "src/components/Account/types"

export type TSubscriptionAddon = components["schemas"]["SubscriptionAddon"]
export type TCurrentSubscription =
  paths["/users/{user_id}/current_subscription"]["get"]["responses"]["200"]["content"]["application/json"]
export type TSubscriptionCurrencyCode = TCurrentSubscription["currency_code"]

export type TPlanRenewalEstimateResponse =
  paths["/subscriptions/estimate/renewal_amount"]["get"]["responses"]["200"]["content"]["application/json"]

export type TPlanRenewalEstimateBreakdownResponse =
  paths["/subscriptions/estimate/renewal_breakdown"]["get"]["responses"]["200"]["content"]["application/json"]

export type TPlanRenewalEstimateBreakdownFilter =
  paths["/subscriptions/estimate/renewal_breakdown"]["get"]["parameters"]["query"]

export type TGroupedRenewalEstimateBreakdown = {
  homegroupName: string
  breakdown: TPlanRenewalEstimateBreakdownResponse["breakdown"]
}[]

export interface IAvailablePlansForUserResponse {
  current_plan?: ICurrentPlan
  number_of_homes?: number
  available_plans: IAvailablePlan[]
}

/**
 * @deprecated This enum is deprecated. Use the string literal instead.
 * Will be removed in GT-319
 */
export enum CurrentPlanStatus {
  ACTIVE = "active",
  FUTURE = "future",
  CANCELLED = "cancelled",
  NON_RENEWING = "non_renewing",
  PAUSED = "paused",
  IN_TRIAL = "in_trial",
  NONE = "none",
}

/**
 * @deprecated This interface is deprecated. Use TCurrentSubscription instead
 * Will be removed in GT-319
 */
export interface ICurrentPlan extends IAvailablePlan {
  legacy_plan?: boolean
  status?: CurrentPlanStatus
  custom_plan?: boolean
}
export interface IAvailablePlan {
  plan_id: string
  plan: "pro" | "standard" | "pro_plus"
  billing_period: "month" | "year"
  currency: "SEK" | "USD" | "CAD" | "GBP" | "EUR" | "AUD"
}

export interface ISubscriptionsResponse {
  subscription: ISubscription
  customer: ICustomer
}

export interface ISubscriptionsBody {
  addons?: {
    id: string
    quantity: number
    trial_end?: number
  }[]
  replace_addon_list?: boolean
  plan_id?: string
  plan_quantity?: number
  reactivate?: boolean
  end_of_term?: boolean
  invoice_immediately?: boolean
  force_term_reset?: boolean
  coupon_ids?: string[]
}

export interface ISubscriptionEstimateBody {
  subscription: {
    id?: string
    plan_id?: string | null
    plan_quantity?: number
  }
  addons?: {
    id: string
    quantity: number
  }[]
  replace_addon_list?: boolean
  invoice_immediately?: boolean
  use_existing_balances?: boolean
  reactivate?: boolean
  end_of_term?: boolean
  force_term_reset?: boolean
  coupon_ids?: string[]
}
export interface ICreateSubscriptionEstimateBody {
  subscription?: {
    id?: string
    plan_id?: string
    plan_quantity?: number
    start_date?: number
  }
  addons?: {
    id: string
    quantity: number
  }[]
  customer?: {
    vat_number?: string
    vat_number_prefix?: string
    registered_for_gst?: boolean
    taxability?: "taxable" | "exempt"
  }
  billing_address?: {
    line1?: string
    line2?: string
    line3?: string
    city?: string
    state_code?: string
    zip?: string
    country?: string
  }
  invoice_immediately?: string
  coupon_ids?: string[]
}

export interface ISubscriptionEstimateResponse {
  estimate: IEstimate
}

export interface IPaymentSourceResponse {
  payment_source: IPaymentSource
}

export interface IPaymentSourceDeleteResponse {
  customer: ICustomer
  payment_source: IPaymentSource
}

export interface IPostUpdateBillingInfoResponse {
  customer: ICustomer
}

export interface IPostUpdateBillingInfo {
  customerId: string
  body: {
    vat_number?: string
    billing_address: IBillingAddress
  }
  updateCache?: boolean
}

export interface ICreatePaymentSourceWithIntentBody {
  customer_id: string
  payment_intent: {
    gateway_account_id: string | undefined
    gw_token: string
  }
}

export type TSubscriptionError =
  | "process_payment_failure"
  | "verification_payment_method_failure"
  | "payment_method_not_present"
  | "payment_currency_incompatible"
  | "invalid_payment_intent"
  | "invalid_gateway_account"
  | "payment_amount_mismatch"
  | "error_transferring_subscription"
  | "plan_not_found"
  | "customer_not_found"
  | "resource_not_found"
  | "error_processing_request"
  | "unknown_invalid_request_error"
  | "authentication_failed"
  | "authorization_failed"
  | "unkown_error"
  | "unknown_payment_error"
  | "coupon_not_applicable_for_plan"
  | "invalid_state_for_request"
  | "unsupported_currency"
  | "max_uses_per_user_exceeded"
  | "max_number_of_homes_exceeded"
  | "coupon_expired"
  | "coupon_not_found"
  | "dns_failed"
  | "integration_requirements_not_met"
  | "unknown_error"
  | "multiple_active_subscriptions"
  | "invalid_subscription_state"

export interface CouponErrorResponse {
  error_code:
    | "coupon_not_applicable_for_plan"
    | "referenced_resource_not_found"
    | "coupon_expired"
}

// This is mocked for now and will be replaced once the API is added
export type TBillingHistoryResponse =
  paths["/organizations/{organization_id}/billing_events"]["get"]["responses"]["200"]["content"]["application/json"]

// This is mocked for now and may be changed once the API is added
export type TBillingHistoryFilter = {
  search_billing_activity_filter?: string
  offset?: number
  limit?: number
}
